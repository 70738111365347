const timelineOptions = {
  chart: {
    id: 'timeline',
    type: 'area',
    brush: {
      enabled: false,
      target: 'main'
    },
    selection: {
      enabled: true
    }
  },
  animations: {
    enabled: false,
  },
  colors: ['#008FFB', '#546E7A'],
  xaxis: {
    type: 'datetime',
    labels: {
      format: 'dd/MM/yyyy HH:mm:ss'
    },
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    tickAmount: 2
  },
  legend: {
    show: false // Disable the native legend
  }
};

let options = {
  chart: {
    id: 'main',
    height: 350,
    type: 'line',
    zoom: {
      enabled: false
    },
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true
      }
    }
  },
  colors: ['#008FFB', '#546E7A'],
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'datetime',
    labels: {
      format: 'dd/MM HH:mm'
    }
  },
  yaxis: {
    min: 0,
    max: 200,
    labels: {
      formatter: function (val) {
        return val.toFixed(2);
      }
    }
  },
  tooltip: {
    x: {
      format: 'dd/MM HH:mm'
    }
  },
  markers: {
    size: 4,
    colors: ['#008FFB', '#546E7A'],
    strokeColors: '#fff',
    strokeWidth: 2,
    hover: {
      size: 7,
    }
  },
  annotations: {
    yaxis: []
  },
  legend: {
    show: false // Disable the native legend
  }
};

export { options, timelineOptions };
