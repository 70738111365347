import React, { useState, useEffect } from 'react';
import { ReactComponent as BoxSvg } from '../assets/images/box-01.svg';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { Search, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ApiService from '../ApiService';
import Charts from '../components/Charts';
import PortModal from '../modals/EditPortModal';
import Battery from '../components/Battery';
import AntennaIndicator from '../components/AntennaIndicator';

const Devices = ({ direction }) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedDeviceId, setExpandedDeviceId] = useState(null);
  const [selectedPort, setSelectedPort] = useState(null);
  const [isPortModalOpen, setIsPortModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)'); // Check if screen size is mobile

  // Fetch devices and their data
  const fetchDevicesData = async (query = '') => {
    setIsLoading(true);
    try {
      const response = await ApiService.getUserNodes(query);
      setDevices(response.nodes || []);
    } catch (error) {
      console.error('Error fetching devices data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDevicesData();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    fetchDevicesData(value.trim());
  };

  const toggleDeviceDetails = (deviceId) => {
    setExpandedDeviceId(expandedDeviceId === deviceId ? null : deviceId);
  };

  const handlePortClick = (port) => {
    setSelectedPort(port);
    setIsPortModalOpen(true);
  };

  const handlePortModalClose = () => {
    setIsPortModalOpen(false);
    setSelectedPort(null);
  };

  return (
    <Container maxWidth="lg">
      <Grid  spacing={2} sx={{ mt: 4 }}>
        {/* Search Field */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <TextField
              variant="outlined"
              placeholder={t('searchDevices')}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <Search />,
              }}
              sx={{ flexGrow: 1, mr: 2 }}
            />
          </Box>
        </Grid>

        {/* Devices Table */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t('devices')}
            </Typography>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer
                sx={{
                  overflowX: 'auto', // Enable horizontal scrolling for smaller screens
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}></TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('uid')}</TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('name')}</TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('locationName')}</TableCell>
                      {!isMobile ?
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('batteryCapacity')}</TableCell>
                      : null}
                      {!isMobile ?
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('RSSI')}</TableCell>
                      : null}
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('lastSeen')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.map((device) => (
                      <React.Fragment key={device.id}>
                        <TableRow>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            <IconButton size="small" onClick={() => toggleDeviceDetails(device.id)}>
                              {expandedDeviceId === device.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                          </TableCell>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{device.uid}</TableCell>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{device.name || t('N/A')}</TableCell>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            {device.location.name || t('N/A')}
                          </TableCell>
                          {!isMobile ?
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            <Battery level={device.battCapacity || 0} />
                          </TableCell>
                          : null}
                          {!isMobile ?
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            <AntennaIndicator rssi={device.rssi || 0} />
                          </TableCell>
                          : null}
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            {device.lastSeen || t('never')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
  <TableCell
    style={{ paddingBottom: 0, paddingTop: 0 }}
    colSpan={7}
    align={direction === 'rtl' ? 'right' : 'left'}
  >
    <Collapse
      in={expandedDeviceId === device.id}
      timeout="auto"
      unmountOnExit
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 2,
          height: '60vh', // Set fixed height
          overflowY: 'auto', // Enable scrolling if content exceeds height
          padding: 2, // Optional: Add padding for better spacing
          border: '1px solid #ddd', // Optional: Add a border for better visibility
        }}
      >
        {/* Chart */}
        <Box
          sx={{
            flex: 1,
            minWidth: isMobile ? '100%' : '60%',
            maxWidth: isMobile ? '100%' : '70%',
          }}
        >
          <Charts node={device} />
        </Box>

        {/* SVG + Ports */}
        {!isMobile && (
          <Box
            sx={{
              flex: 1,
              textAlign: 'center',
              minWidth: isMobile ? '0%' : '40%',
              maxWidth: isMobile ? '0%' : '30%',
            }}
          >
            <BoxSvg
              style={{
                width: '100%',
                maxWidth: 200,
                height: 'auto',
                margin: '0 auto',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                justifyContent: 'center',
                mt: 2,
              }}
            >
              {(direction === 'rtl' ? ['D', 'C', 'B', 'A'] : ['A', 'B', 'C', 'D']).map(
                (portUid) => {
                  const port = device.ports.find((p) => p.uid === portUid);
                  const isAvailable = port.connected;

                  return (
                    <Box
                      key={portUid}
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: isAvailable
                          ? 'rgb(76, 175, 80)'
                          : 'rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #ccc',
                        cursor: isAvailable ? 'pointer' : 'not-allowed',
                      }}
                      onClick={isAvailable ? () => handlePortClick(port) : undefined}
                    >
                      {portUid}
                    </Box>
                  );
                }
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Collapse>
  </TableCell>
</TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Port Modal */}
      <PortModal
        open={isPortModalOpen}
        onClose={handlePortModalClose}
        port={selectedPort}
        sensors={selectedPort?.sensors || []}
        t={t}
      />
    </Container>
  );
};

export default Devices;
