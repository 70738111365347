import React from "react";

const AntennaIndicator = ({ rssi }) => {
  // Determine the color and number of filled bars based on RSSI value
  const getAntennaAttributes = (rssiValue) => {
    if (rssiValue >= -50) {
      return { color: "green", filledBars: 4 };
    } else if (rssiValue >= -70) {
      return { color: "yellow", filledBars: 3 };
    } else if (rssiValue >= -90) {
      return { color: "orange", filledBars: 2 };
    } else {
      return { color: "red", filledBars: 1 };
    }
  };

  const { color, filledBars } = getAntennaAttributes(rssi);

  return (
    <div style={{ display: "flex", flexDirection: "column-reverse", alignItems: "center" }}>
      {[1, 2, 3, 4].map((level) => (
        <div
          key={level}
          style={{
            width: `${level * 10}px`,
            height: "6px",
            margin: "2px 0",
            backgroundColor: level <= filledBars ? color : "#ccc",
            borderRadius: "2px",
          }}
        />
      ))}
    </div>
  );
};

export default AntennaIndicator;
