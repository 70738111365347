import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Modal,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import {
  Add,
  Edit,
  LocationOn,
  SensorDoor,
  Lock,
  AttachMoney,
  DataUsage,
  Search,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import ApiService from '../../ApiService';
import PermissionManager from './PermissionManager';

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async (query = '') => {
    setIsLoadingAccounts(true);
    try {
      const response = await ApiService.getAllAccounts(query);
      if (response.error === "false") {
        setAccounts(response.accounts || []);
      }
    } catch (error) {
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const handleAccountSelect = async (account) => {
    setSelectedAccount(account);
    setUsers([]);
    setSelectedUser(null);
    setIsLoadingUsers(true);
    try {
      const response = await ApiService.getAllUsersUnderAccount(account.id);
      setUsers(response.users || []);
    } catch (error) {
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim()) {
      fetchAccounts(value.trim());
    } else {
      fetchAccounts();
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUserModalOpen = () => {
    setUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setUserModalOpen(false);
  };

  const handleEditUserModalOpen = () => {
    setEditUserModalOpen(true);
  };

  const handleEditUserModalClose = () => {
    setEditUserModalOpen(false);
  };

  const handleAddAccount = () => {
    // Add logic to handle adding an account
    setModalOpen(false);
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
  }

  const handleAddUser = () => {
    // Add logic to handle adding a user to the selected account
    setUserModalOpen(false);
  };

  const handleEditUser = () => {
    // Add logic to handle editing a user
    setEditUserModalOpen(false);
  };

  const AccountRow = ({ index, style }) => {
    const account = accounts[index];
    return (
      <ListItem
        style={style}
        button
        key={account.id}
        selected={selectedAccount?.id === account.id}
        onClick={() => handleAccountSelect(account)}
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(0, 123, 255, 0.2)',
          },
          backgroundColor: selectedAccount?.id === account.id ? 'rgba(0, 123, 255, 0.2)' : 'inherit',
        }}
      >
        <ListItemText
          primary={account.name}
          secondary={`ID: ${account.id} | CRM: ${account.crmId || t('noCRM')}`}
        />
      </ListItem>
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {/* Top Buttons */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Button variant="contained" startIcon={<Add />} onClick={() => setModalOpen(true)}>
              {t('addAccount')}
            </Button>
            <Button
              variant="contained"
              startIcon={<Add />}
              disabled={!selectedAccount}
              onClick={() => setUserModalOpen(true)}
            >
              {t('addUser')}
            </Button>
            <Button
              variant="contained"
              startIcon={<Edit />}
              disabled={!selectedUser}
              onClick={() => setEditUserModalOpen(true)}
            >
              {t('editUser')}
            </Button>
          </Box>
        </Grid>

        {/* Accounts Section */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t('accounts')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={t('searchAccount')}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />
            {isLoadingAccounts ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <FixedSizeList
                height={300}
                width="100%"
                itemSize={60}
                itemCount={accounts.length}
                overscanCount={5}
              >
                {AccountRow}
              </FixedSizeList>
            )}
          </Paper>
        </Grid>

        {/* Users Section */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {selectedAccount ? `${t('usersUnder')} ${selectedAccount.name}` : t('selectAccount')}
            </Typography>
            {isLoadingUsers ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : users.length === 0 ? (
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center', color: 'gray' }}>
                {t('noUsersFound')}
              </Typography>
            ) : (
              <List>
                {users.map((user) => (
                  <ListItem
                    button
                    key={user.id}
                    selected={selectedUser?.id === user.id}
                    onClick={() => handleUserSelect(user)}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(0, 123, 255, 0.2)',
                      },
                      backgroundColor: selectedUser?.id === user.id ? 'rgba(0, 123, 255, 0.2)' : 'inherit',
                    }}
                  >
                    <ListItemText primary={user.username} secondary={user.role} />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>

        {/* Tabs Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
              <Tab label={t('permissions')} icon={<Lock />} />
              <Tab label={t('locations')} icon={<LocationOn />} />
              <Tab label={t('controllers')} icon={<SensorDoor />} />
              <Tab label={t('sensors')} icon={<AttachMoney />} />
              <Tab label={t('dataUsage')} icon={<DataUsage />} />
              <Tab label={t('billing')} icon={<AttachMoney />} />
            </Tabs>
          </Paper>
          <Paper sx={{ p: 2, mt: 2 }}>
            {selectedUser ? (
              <>
                {selectedTab === 0 && (
                  <Box>
                    <Typography variant="h6">{t('permissions')}</Typography>
                    <PermissionManager userPermissions={selectedUser?.permissions} />
                  </Box>
                )}
                {selectedTab === 1 && (
                  <Box>
                    <Typography variant="h6">{t('locations')}</Typography>
                    {/* Add content for locations */}
                  </Box>
                )}
                {selectedTab === 2 && (
                  <Box>
                    <Typography variant="h6">{t('controllers')}</Typography>
                    {/* Add content for controllers */}
                  </Box>
                )}
                {selectedTab === 3 && (
                  <Box>
                    <Typography variant="h6">{t('sensors')}</Typography>
                    {/* Add content for sensors */}
                  </Box>
                )}
                {selectedTab === 4 && (
                  <Box>
                    <Typography variant="h6">{t('dataUsage')}</Typography>
                    {/* Add content for data usage */}
                  </Box>
                )}
                {selectedTab === 5 && (
                  <Box>
                    <Typography variant="h6">{t('billing')}</Typography>
                    {/* Add content for billing */}
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body1" color="textSecondary">
                  {t('pleaseSelectUser')}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      
       {/* Add Account Modal */}
       <Modal open={isModalOpen} onClose={handleModalClose}>
        <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
          <Typography variant="h6" gutterBottom>{t('addAccount')}</Typography>
          <TextField label={t('accountName')} fullWidth sx={{ mb: 2 }} />
          <TextField label={t('email')} fullWidth sx={{ mb: 2 }} />
          <Button variant="contained" fullWidth onClick={handleAddAccount}>{t('save')}</Button>
        </Paper>
      </Modal>

      {/* Add User Modal */}
      <Modal open={isUserModalOpen} onClose={handleUserModalClose}>
        <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
          <Typography variant="h6" gutterBottom>{t('addUser')}</Typography>
          <TextField label={t('username')} fullWidth sx={{ mb: 2 }} />
          <TextField label={t('email')} fullWidth sx={{ mb: 2 }} />
          <Button variant="contained" fullWidth onClick={handleAddUser}>{t('save')}</Button>
        </Paper>
      </Modal>

      {/* Edit User Modal */}
      <Modal open={isEditUserModalOpen} onClose={handleEditUserModalClose}>
        <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
          <Typography variant="h6" gutterBottom>{t('editUser')}</Typography>
          <TextField label={t('username')} fullWidth defaultValue={selectedUser?.username} sx={{ mb: 2 }} />
          <TextField label={t('email')} fullWidth defaultValue={selectedUser?.email} sx={{ mb: 2 }} />
          <Button variant="contained" fullWidth onClick={handleEditUser}>{t('save')}</Button>
        </Paper>
      </Modal>

      </Grid>
    </Container>
  );
};

export default AdminDashboard;
