import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import routesConfig from './configs/routesConfig';

const AppRoutes = ({ direction }) => {
  return (
    <Routes>
      {Object.keys(routesConfig).flatMap((categoryId) => 
        routesConfig[categoryId].items.map((routeConfig) => (
          <Route
            key={routeConfig.path}
            path={routeConfig.path}
            element={<routeConfig.component direction={direction} />}
          />
        ))
      )}
      {/* Fallback route for unknown paths */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;