import React from 'react';
import { Box, Typography } from '@mui/material';

const Battery = ({ level }) => {
  const getBatteryColor = () => {
    if (level <= 20) return 'red';
    if (level <= 40) return 'orange';
    return 'green';
  };

  return (
    <Box
      sx={{
        width: 60,
        height: 30,
        border: '2px solid black',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#e0e0e0',
        overflow: 'hidden',
      }}
    >
      {/* Battery Fill */}
      <Box
        sx={{
          width: `${Math.min(level, 100)}%`,
          height: '100%',
          backgroundColor: getBatteryColor(),
          transition: 'width 0.3s ease-in-out',
        }}
      ></Box>

      {/* Battery Label */}
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {`${level}%`}
      </Typography>

      {/* Battery Tip */}
      <Box
        sx={{
          width: 6,
          height: 12,
          backgroundColor: 'black',
          borderRadius: '2px',
          position: 'absolute',
          right: '-8px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      ></Box>
    </Box>
  );
};

export default Battery;
