import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Typography, Box, Grid, Button, TextField, CircularProgress } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { options as initialOptions } from '../configs/chartsOptions';
import GaugeComponent from './Gauge';
import ApiService from '../ApiService';

const ApexCharts = ({ node, direction }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [groupedSensors, setGroupedSensors] = useState({});
  const [sensorVisibility, setSensorVisibility] = useState({});
  const [dateRange, setDateRange] = useState({
    from: new Date(Date.now() - 24 * 60 * 60 * 1000),
    to: new Date(),
  });
  const [chartLoading, setChartLoading] = useState(false);

  useEffect(() => {
    const fetchControllerCharts = async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);
    };

    fetchControllerCharts();
  }, []);

  const fetchData = async (customRange) => {
    const range = customRange || dateRange;
    setChartLoading(true); // Start chart-specific loading
    try {
      const { from, to } = range;

      // Format the date range as timestamps
      const params = {
        nodeId: node.id,
        from: from.getTime(),
        to: to.getTime(),
      };

      // Call the API using ApiService
      const response = await ApiService.getDataByNode(params);

      if (response.error) {
        console.error('Error fetching data:', response.message);
        return;
      }

      // Extract and set data
      const dataSample = response;
      setData(dataSample);

      // Group sensors by type
      const grouped = dataSample.sensors.reduce((acc, sensor) => {
        if (!acc[sensor.type]) {
          acc[sensor.type] = [];
        }
        acc[sensor.type].push(sensor);
        return acc;
      }, {});

      setGroupedSensors(grouped);

      // Initialize visibility state for all sensors
      const initialVisibility = dataSample.sensors.reduce((acc, sensor) => {
        acc[sensor.id] = true; // Default: All sensors visible
        return acc;
      }, {});
      setSensorVisibility(initialVisibility);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setChartLoading(false); // End chart-specific loading
    }
  };

  const handleDateRangeChange = (key, newValue) => {
    setDateRange((prev) => ({
      ...prev,
      [key]: newValue,
    }));
    fetchData({ ...dateRange, [key]: newValue }); // Refresh chart data
  };

  const handleQuickRange = (days) => {
    const newRange = {
      from: new Date(Date.now() - days * 24 * 60 * 60 * 1000),
      to: new Date(),
    };
    setDateRange(newRange);
    fetchData(newRange); // Refresh chart data
  };

  const renderChart = (type) => {
    const chartOptions = { ...initialOptions };

    return (
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {chartLoading ? (
          <CircularProgress />
        ) : (
          <Grid item xs={11}>
            <ReactApexChart
              style={{ marginTop: '10px' }}
              options={chartOptions}
              series={renderSeriesData(type)}
              type="line"
              width={'100%'}
            />
                    {groupedSensors[type]?.map((sensor) => renderSensorToggles(sensor))}
          </Grid>
          
        )}
      </Grid>
    );
  };

  const renderSeriesData = (type) => {
    const sensorsOfType = groupedSensors[type] || [];
    return sensorsOfType
      .filter((sensor) => sensorVisibility[sensor.id])
      .map((sensor) => ({
        name: `${t('sensor')} ${sensor.port}`,
        data: sensor.data.map((point) => ({
          x: parseInt(point.timestamp, 10),
          y: point.value,
        })),
      }));
  };

  const renderSensorToggles = (selectedSensor) => (
    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
      <label key={selectedSensor.id}>
        <input
          type="checkbox"
          checked={sensorVisibility[selectedSensor.id]}
          onChange={() => toggleSensorVisibility(selectedSensor.id)}
        />
        {`${t('sensor')} ${selectedSensor.port}`}
      </label>
    </Box>
  );

  const toggleSensorVisibility = (sensorId) => {
    setSensorVisibility((prev) => ({
      ...prev,
      [sensorId]: !prev[sensorId],
    }));
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <DatePicker
              label={t('fromDate')}
              value={dateRange.from}
              onChange={(newValue) => handleDateRangeChange('from', newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label={t('toDate')}
              value={dateRange.to}
              onChange={(newValue) => handleDateRangeChange('to', newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button variant="outlined" onClick={() => handleQuickRange(1)}>
          {t('24hours')}
        </Button>
        <Button variant="outlined" onClick={() => handleQuickRange(7)}>
          {t('7days')}
        </Button>
        <Button variant="outlined" onClick={() => handleQuickRange(30)}>
          {t('30days')}
        </Button>
        <Button variant="outlined" onClick={() => handleQuickRange(90)}>
          {t('3months')}
        </Button>
      </Box>

      <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} centered>
        {Object.keys(groupedSensors).map((type, index) => (
          <Tab key={index} label={<Typography variant="body1">{t(type)}</Typography>} />
        ))}
      </Tabs>
      {Object.keys(groupedSensors).map((type, index) => (
        <TabPanel key={index} value={value} index={index}>
          {renderChart(type)}
        </TabPanel>
      ))}
    </Box>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

export default ApexCharts;
