import axios from 'axios';

// Define your API base URL
//const API_BASE_URL = 'http://localhost:1337/api/v2/';
const API_BASE_URL = 'https://cloud.omniot.io/api/v2/';

class ApiService {
  // Set up axios instance with base URL and common headers
  constructor() {
    this.api = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Interceptor to add auth token to headers if present
    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem('authToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  // Method to handle login
  async login(username, password) {
    try {
      const response = await this.api.post('/auth/login', { username, password });
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // Method to get all accounts (admin use)
  async getAllAccounts(accountName) {
    try {
      // Construct the query parameter if accountName is provided
      const query = accountName ? `?account=${encodeURIComponent(accountName)}` : '';
      const response = await this.api.get(`/admin/accounts${query}`);
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  async getUserNodes(){
    try {
      const response = await this.api.get(`/nodes`);
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  async getNodeSensors(nodeId){
    try {
      const response = await this.api.get(`/nodes/${nodeId}/sensors`);
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // method to get sensor by portId
  async getSensorsByPortId(nodeId,portId){
    try {
      const response = await this.api.get(`/nodes/${nodeId}/port/${portId}`);
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // method to get sensor by portId
  async getAllPermissions(){
    try {
      const response = await this.api.get(`/auth/permissions`);
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // Method to get data by node ID and date range
  async getDataByNode(params) {
    try {
      const { nodeId, from, to } = params;

      // Validate parameters
      if (!nodeId || !from || !to) {
        throw this._handleError('Missing required parameters: nodeId, from, or to');
      }

      // Perform the API call
      const response = await this.api.get(`/data/${nodeId}/fetch`, {
        params: { from, to }, // Pass the date range as query parameters
      });

      return response.data; // Return the response data
    } catch (error) {
      throw this._handleError(error); // Handle and throw error
    }
  }

  // Method to get all users (admin use)
  async getAllUsersUnderAccount(accountId) {
    try {
      // Use template literals to include accountId in the URL
      const response = await this.api.get(`/admin/account/${accountId}/users`);
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // Method to handle registration
  async register(username, password) {
    try {
      const response = await this.api.post('/register', { username, password });
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // Method to fetch user profile
  async getUserProfile() {
    try {
      const response = await this.api.get('/auth/me');
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // Method to update user profile
  async updateUserProfile(profileData) {
    try {
      const response = await this.api.put('/profile', profileData);
      return response.data;
    } catch (error) {
      throw this._handleError(error);
    }
  }

  // Method to handle API errors
  _handleError(error) {
    if (error.response) {
      // Request made and server responded with a status code
      return new Error(`Server Error: ${error.response.status} - ${error.response.data.message}`);
    } else if (error.request) {
      // The request was made but no response was received
      return new Error('Network Error: No response received');
    } else {
      // Something happened in setting up the request
      return new Error(`Request Error: ${error.message}`);
    }
  }
}

export default new ApiService();