import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from 'react-i18next';

const predefinedColors = [
  { name: "red", hex: "#f44336" },
  { name: "blue", hex: "#2196f3" },
  { name: "green", hex: "#4caf50" },
  { name: "yellow", hex: "#ffeb3b" },
  { name: "purple", hex: "#9c27b0" },
  { name: "orange", hex: "#ff9800" },
  { name: "cyan", hex: "#00bcd4" },
  { name: "pink", hex: "#e91e63" },
];

const dataTypes = ["number", "string", "boolean", "datetime"];

// Map of data types to relevant units
const unitOptions = {
  number: ["mm", "cm", "m", "km", "kg", "g", "liter", "ml", "%", "ppm", "ppb"],
  string: ["unit", "count", "score", "index"],
  boolean: [],
  datetime: ["ms", "s", "min", "hr", "day", "week", "month", "year"],
};

// Demo additives for the dropdown
const demoAdditives = [
  {
    id: 1,
    name: "CONFIDOR for garden",
    activeIngredient: "IMIDACLOPRID",
    licenseNumber: 1379,
  },
];

const CategoryModal = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [categoryData, setCategoryData] = useState({
    name: "",
    description: "",
    color: predefinedColors[0].hex, // Default to the first color
    dataType: "",
    unit: "",
    linkToAdditive: false, // New field for checkbox
    additiveId: null, // Selected additive ID
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData((prevData) => ({ ...prevData, [name]: value }));

    // Reset unit if dataType changes
    if (name === "dataType") {
      setCategoryData((prevData) => ({ ...prevData, unit: "" }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setCategoryData((prevData) => ({
      ...prevData,
      linkToAdditive: checked,
      additiveId: checked ? categoryData.additiveId : null, // Reset additive ID if unchecked
    }));
  };

  const handleSubmit = () => {
    if (!categoryData.name || !categoryData.color || !categoryData.dataType) {
      alert("Please fill in all required fields.");
      return;
    }
    if (categoryData.linkToAdditive && !categoryData.additiveId) {
      alert("Please select an additive if 'Link to Additive' is checked.");
      return;
    }
    onSubmit(categoryData); // Pass the data back to the parent component
    setCategoryData({
      name: "",
      description: "",
      color: predefinedColors[0].hex,
      dataType: "",
      unit: "",
      linkToAdditive: false,
      additiveId: null,
    });
    onClose();
  };

  // Get relevant units for the selected data type
  const relevantUnits = unitOptions[categoryData.dataType] || [];

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          {t("createCategory")}
        </Typography>
        <TextField
          fullWidth
          label={t("categoryName")}
          name="name"
          value={categoryData.name}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label={t("description")}
          name="description"
          value={categoryData.description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={3}
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel>{t("dataType")}</InputLabel>
          <Select name="dataType" value={categoryData.dataType} onChange={handleChange}>
            {dataTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {categoryData.dataType && relevantUnits.length > 0 && (
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("unit")}</InputLabel>
            <Select name="unit" value={categoryData.unit} onChange={handleChange}>
              {relevantUnits.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth margin="normal" required>
          <InputLabel>{t("categoryColor")}</InputLabel>
          <Select
            name="color"
            value={categoryData.color}
            onChange={handleChange}
            renderValue={(value) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: value,
                    borderRadius: "50%",
                    mr: 1,
                  }}
                />
                {predefinedColors.find((color) => color.hex === value)?.name || value}
              </Box>
            )}
          >
            {predefinedColors.map((color) => (
              <MenuItem key={color.hex} value={color.hex}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: color.hex,
                    borderRadius: "50%",
                    mr: 2,
                  }}
                />
                {t(color.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={categoryData.linkToAdditive}
              onChange={handleCheckboxChange}
            />
          }
          label={t("linkToAdditive")}
        />
        {categoryData.linkToAdditive && (
          <FormControl fullWidth margin="normal" required>
            <InputLabel>{t("selectAdditive")}</InputLabel>
            <Select
              name="additiveId"
              value={categoryData.additiveId}
              onChange={handleChange}
            >
              {demoAdditives.map((additive) => (
                <MenuItem key={additive.id} value={additive.id}>
                  {additive.name} - {additive.activeIngredient}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            {t("cancel")}
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            {t("create")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CategoryModal;
