import React, { useState, useContext } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Language } from '@mui/icons-material';
// contexts
import { LanguageContext } from '../../contexts/LanguageContext'; // Import LanguageContext

const LanguageMenu = () => {
  const { toggleLanguage } = useContext(LanguageContext); // Access toggleLanguage from context
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    toggleLanguage(language); // Use the LanguageContext function
    handleClose();
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="language"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Language />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
      >
        <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('he')}>Hebrew</MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageMenu;
