import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useTranslation } from 'react-i18next';

const TaskModal = ({ open, onClose, onSubmit, categories }) => {
  const { t } = useTranslation();
  const [taskData, setTaskData] = useState({
    title: "",
    description: "",
    category: "",
    date: "",
    status: "pending",
    resultValue: "",
    imageUrl: "",
  });
  const [resultType, setResultType] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaskData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    const selectedCategory = categories.find((cat) => cat.id === selectedCategoryId);
    setResultType(selectedCategory?.resultType || null);
    setTaskData((prevData) => ({
      ...prevData,
      category: selectedCategoryId,
      resultValue: "",
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setTaskData((prevData) => ({ ...prevData, imageUrl: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    if (!taskData.title || !taskData.category || !taskData.date) {
      alert(t('pleaseFillAlRequiredFields'));
      return;
    }

    if (resultType) {
      const { dataType } = resultType;
      const value = taskData.resultValue;
      if (
        (dataType === "number" && isNaN(value)) ||
        (dataType === "boolean" && !["true", "false"].includes(value.toLowerCase())) ||
        (dataType === "datetime" && isNaN(Date.parse(value)))
      ) {
        alert('Invalid value for' + resultType.name + 'Expected a' + dataType);
        return;
      }
    }

    onSubmit(taskData); // Pass the data back to the parent component
    setTaskData({
      title: "",
      description: "",
      category: "",
      date: "",
      status: "pending",
      resultValue: "",
      imageUrl: "",
    });
    setImagePreview(null);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          {t('createTask')}
        </Typography>

        {/* Image Upload Section */}
        <Box
          sx={{
            width: "100%",
            height: 200,
            borderRadius: 2,
            backgroundColor: "#f4f4f4",
            border: "2px dashed #ccc",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            overflow: "hidden",
            mb: 3,
            position: "relative",
          }}
        >
          {imagePreview ? (
            <Box
              component="img"
              src={imagePreview}
              alt="Task Preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <>
              <AddPhotoAlternateIcon sx={{ fontSize: 48, color: "#aaa" }} />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {t('uploadImage')}
              </Typography>
            </>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
          />
        </Box>

        {/* Task Form */}
        <TextField
          fullWidth
          label={t('taskTitle')}
          name="title"
          value={taskData.title}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label={t('description')}
          name="description"
          value={taskData.description}
          onChange={handleChange}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('category')}</InputLabel>
          <Select
            name="category"
            value={taskData.category}
            onChange={handleCategoryChange}
            required
          >
            {categories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={t('date')}
          type="date"
          name="date"
          value={taskData.date}
          onChange={handleChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
        {resultType && (
          <TextField
            fullWidth
            label={t('Enter') + " " + resultType.name + " (" + t(resultType.dataType) + ")"}
            name="resultValue"
            value={taskData.resultValue}
            onChange={handleChange}
            margin="normal"
            required
            type={resultType.dataType === "number" ? "number" : "text"}
          />
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={onClose} sx={{ mr: 2 }}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            {t('create')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TaskModal;
