import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ApiService from '../../ApiService'; 

const PermissionManager = ({ userPermissions, onSave }) => {
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(userPermissions || {});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const data = await ApiService.getAllPermissions();
        setPermissions(data.permissions); // Assuming the API returns permissions grouped by category
      } catch (error) {
        console.error('Failed to fetch permissions:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  const handleCheckboxChange = (category, route, permissionType) => {
    setPermissions((prev) => ({
      ...prev,
      [category]: prev[category].map((item) =>
        item.route === route
          ? { ...item, [permissionType]: !item[permissionType] }
          : item
      ),
    }));
  };

  if (loading) {
    return <Typography>{t('loadingPermissions')}</Typography>;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        {t('managePermissions')}
      </Typography>
      <Grid container spacing={2}>
        {Object.keys(permissions).map((category) => (
          <Grid item xs={12} md={4} key={category}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">{t(category)}</Typography>
              <Box sx={{ minHeight: 200, mt: 2, p: 1, border: '1px dashed gray' }}>
                {permissions[category].map((item) => (
                  <Paper key={item.route} sx={{ mb: 2, p: 2 }}>
                    <List>
                      <ListItem>
                        <ListItemText primary={item.route} />
                      </ListItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.read}
                            onChange={() =>
                              handleCheckboxChange(category, item.route, 'read')
                            }
                          />
                        }
                        label={t('read')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.write}
                            onChange={() =>
                              handleCheckboxChange(category, item.route, 'write')
                            }
                          />
                        }
                        label={t('write')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.delete}
                            onChange={() =>
                              handleCheckboxChange(category, item.route, 'delete')
                            }
                          />
                        }
                        label={t('delete')}
                      />
                    </List>
                  </Paper>
                ))}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => onSave(permissions)}
        >
          {t('savePermissions')}
        </Button>
      </Box>
    </Box>
  );
};

export default PermissionManager;
