import React, { useContext, useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useLocation} from 'react-router-dom';
import { Box, AppBar, Toolbar, IconButton, Typography, useTheme, useMediaQuery, Fab } from '@mui/material';
import { Menu } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Import WhatsApp icon
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import SideNav from './components/navigation/SideNav';
import ProfileMenu from './components/navigation/ProfileMenu';
import LanguageMenu from './components/navigation/LanguageMenu';
import NotificationMenu from './components/navigation/NotificationMenu';
import { useTranslation } from 'react-i18next';
//import { createTheme, StyledEngineProvider } from '@mui/material/styles';
//import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { TextField, createTheme, ThemeProvider as EmotionThemeProvider, CssBaseline, StyledEngineProvider} from '@mui/material';
import AppRoutes from './routes'; // Import the routes
import Login from './pages/Login'; // Import the Login component
// contexts
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { LanguageProvider, LanguageContext } from './contexts/LanguageContext';

const AppContent = () => {
  const { isAuthenticated, permissions, loading } = useContext(AuthContext); // Include `loading`
  const { cache, direction } = useContext(LanguageContext);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const location = useLocation();

  const drawerWidth = useMediaQuery(theme.breakpoints.up('sm')) ? 240 : 200;

  const [mobileOpen, setMobileOpen] = useState(false);

  const themeWithDirection = createTheme({
    direction,
    ...theme,
  });

  // WhatsApp redirect handler
  const redirectToWhatsApp = () => {
    window.location.href = 'https://whatsapp.com/channel/0029VaJ3gRp60eBXB32h4Z2j';
  };

  // Store the current route in localStorage whenever the location changes
  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('currentRoute', location.pathname + location.search);
    }
  }, [location, isAuthenticated]);

  // Check localStorage and navigate to the saved route after refresh
  useEffect(() => {
    const savedRoute = localStorage.getItem('currentRoute');
    if (savedRoute && isAuthenticated) {
      window.location.hash = savedRoute; // Set the hash to the saved route
    }
  }, [isAuthenticated]);

  // Show a loading spinner while validation is in progress
  if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2000, // Ensure it sits above other content
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            color: 'white',
            padding: 3,
            borderRadius: 1,
            backdropFilter: 'blur(10px)', // Smooth blur effect
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2, // Space below the spinner
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                border: '4px solid rgba(255, 255, 255, 0.2)',
                borderTop: '4px solid blue',
                borderRadius: '50%',
                animation: 'spin 1s linear infinite',
              }}
            />
          </Box>
        </Box>
  
        {/* Keyframes for spinning animation */}
        <style>{`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </Box>
    );
  }

  const rtlTheme = createTheme({
    direction: 'rtl', // Set direction globally for RTL languages
  });

  const ltrTheme = createTheme({
    direction: 'ltr', // Set direction globally for RTL languages
  });
  
  return (
    <CacheProvider value={cache}>
      <EmotionThemeProvider theme={themeWithDirection}>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', direction }}>
          <CssBaseline />

          {isAuthenticated && (
            <>
              <AppBar
                position="fixed"
                sx={{
                  zIndex: theme.zIndex.drawer + 1,
                  ml: { sm: direction === 'rtl' ? 0 : `${drawerWidth}px` },
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                  ...(direction === 'rtl' && { mr: { sm: `${drawerWidth}px` } }),
                }}
              >
                <Toolbar>
                  {!isMdUp && (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={() => setMobileOpen(!mobileOpen)}
                      sx={{ mr: 2 }}
                    >
                      <Menu />
                    </IconButton>
                  )}
                  <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    {t('dashboard')}
                  </Typography>
                  <NotificationMenu />
                  <LanguageMenu />
                  <ProfileMenu />
                </Toolbar>
              </AppBar>
              <SideNav
                mobileOpen={mobileOpen}
                handleDrawerToggle={() => setMobileOpen(!mobileOpen)}
                direction={direction}
                permissions={permissions} // Pass permissions dynamically
              />
            </>
          )}

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              pt: isAuthenticated ? `${theme.spacing(8)}` : '0',
              ml: isAuthenticated ? { sm: `${drawerWidth}px` } : '0',
              mr: isAuthenticated ? { sm: `${drawerWidth}px` } : '0',
              width: isAuthenticated ? { sm: `calc(100% - ${drawerWidth}px)` } : '100%',
            }}
          >
            <Routes>
              <Route path="/login" element={isAuthenticated ? <Navigate to="/" replace /> : <Login />} />
              <Route
                path="*"
                element={isAuthenticated ? <AppRoutes permissions={permissions} direction={direction} /> : <Navigate to="/login" replace />}
              />
            </Routes>
          </Box>

          {/* Floating WhatsApp Icon */}
          <Fab
            color="success"
            aria-label="WhatsApp"
            sx={{
              position: 'fixed',
              bottom: 16,
              [direction === 'rtl' ? 'left' : 'right']: 16,
              zIndex: 1000,
            }}
            onClick={redirectToWhatsApp}
          >
            <WhatsAppIcon />
          </Fab>
        </Box>
      </EmotionThemeProvider>
    </CacheProvider>
  );
};



const App = () => (
  <StyledEngineProvider injectFirst>
    <Router> {/* Wrap the entire app with HashRouter */}
      <AuthProvider>
        <LanguageProvider>
          <AppContent />
        </LanguageProvider>
      </AuthProvider>
    </Router>
  </StyledEngineProvider>
);

export default App;
