import { Home, Person, Assignment, Settings, ShareLocation, Colorize, DevicesOther, Payment, QrCode, PeopleAlt } from '@mui/icons-material';
import HomePage from '../pages/Home';
import DevicesPage from '../pages/Devices';
import LocationsPage from '../pages/Locations';
import ProfilePage from '../pages/account/Profile';
import PaymentsPage from '../pages/account/Payments';
import UsersPage from '../pages/account/Users';
import AdminDashboardPage from '../pages/admin/AdminDashboard';
import BarcodesPage from '../pages/warehouse/Barcodes';
// task management
import TasksManagement from '../pages/taskmanager/TaskManagementPage';
import Additives from '../pages/taskmanager/Additives';

const routesConfig = {
  main: {
    name: 'farm_dashboard',
    items: [
      {
        icon: <Home />,
        text: 'dashboard',
        route: '/',
        path: '/',
        component: HomePage,
        permissions: ['main'], // Define required permissions
      },
      {
        icon: <DevicesOther />,
        text: 'devices',
        route: '/devices',
        path: '/devices',
        component: DevicesPage,
        permissions: ['main'],
      },
      {
        icon: <ShareLocation />,
        text: 'locations',
        route: '/locations',
        path: '/locations',
        component: LocationsPage,
        permissions: ['main'],
      },
    ],
  },
  tasksManagement:{
    name: 'tasks',
    items: [
      {
        icon: <Assignment />,
        text: 'tasks',
        route: '/tasks',
        path: '/tasks',
        component: TasksManagement,
        permissions: ['tasks_management'],
      },
      {
        icon: <Colorize />,
        text: 'additives',
        route: '/tasks/additives',
        path: '/tasks/additives',
        component: Additives,
        permissions: ['tasks_additives'],
      },
    ],
  },
  packagingHouse: {
    name: 'packaging_house',
    items: [
      {
        icon: <QrCode />,
        text: 'barcodes',
        route: '/barcodes',
        path: '/barcodes',
        component: BarcodesPage,
        permissions: ['packaging_house'],
      },
    ],
  },
  account: {
    name: 'account',
    items: [
      {
        icon: <Person />,
        text: 'profile',
        route: '/profile',
        path: '/profile',
        component: ProfilePage,
        permissions: ['account'],
      },
      {
        icon: <PeopleAlt />,
        text: 'users',
        route: '/users',
        path: '/users',
        component: UsersPage,
        permissions: ['account'],
      },
      {
        icon: <Payment />,
        text: 'payments',
        route: '/payments',
        path: '/payments',
        component: PaymentsPage,
        permissions: ['account'],
      }
    ],
  },
  admin: {
    name: 'admin',
    items: [
      {
        icon: <Settings />,
        text: 'adminDashboard',
        route: '/admin/dashboard',
        path: '/admin/dashboard',
        component: AdminDashboardPage,
        permissions: ['admin'],
      },
    ],
  },
};

export default routesConfig;
