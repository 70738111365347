import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Tab,
  Tabs,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const AdditivesPage = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0); // 0 for Database, 1 for My Additives
  const [additives, setAdditives] = useState([]);
  const [myAdditives, setMyAdditives] = useState([
    {
      _id: 1,
      "מספר רשיון": 1379,
      "שם תכשיר": "קונפידור לגן",
      "שם תכשיר אנגלי": "CONFIDOR for garden",
      "חומר פעיל": "IMIDACLOPRID",
      "רעילות": "רעיל מאד לדבורים",
      "תווית":
        "https://pesticides.moag.gov.il/coim/Documents/GetFile?folder=Import&name=1379",
    },
  ]); // Demo saved additive
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const ITEMS_PER_PAGE = 10;
  const API_URL = "https://data.gov.il/api/3/action/datastore_search";

  const fetchAdditives = async (page, query = "") => {
    setLoading(true);
    try {
      const offset = (page - 1) * ITEMS_PER_PAGE;
      const response = await axios.get(API_URL, {
        params: {
          resource_id: "cffe0c50-6856-4187-9315-51bc113cb718",
          limit: ITEMS_PER_PAGE,
          offset: offset,
          q: query,
        },
      });

      const { records, total } = response.data.result;
      setAdditives(records);
      setTotalPages(Math.ceil(total / ITEMS_PER_PAGE));
    } catch (error) {
      console.error("Error fetching additives:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (tabIndex === 0) {
      fetchAdditives(currentPage, searchQuery);
    }
  }, [currentPage, searchQuery, tabIndex]);

  const handlePageChange = (direction) => {
    setCurrentPage((prev) => prev + direction);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when performing a new search
  };

  const toggleDetails = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleAddRemoveAdditive = (additive) => {
    const exists = myAdditives.some((item) => item._id === additive._id);
    if (exists) {
      setMyAdditives((prev) => prev.filter((item) => item._id !== additive._id));
    } else {
      setMyAdditives((prev) => [...prev, additive]);
    }
  };

  const isAdditiveInMyList = (id) => myAdditives.some((item) => item._id === id);

  const getToxicityColor = (toxicity) => {
    if (!toxicity) return "transparent"; // Default color if no toxicity information
    if (toxicity.includes("רעיל מאד") || toxicity.includes("יצורים ימיים")) return "#f44336"; // Red
    if (toxicity.includes("לא רעיל")) return "#4caf50"; // Green
    if (toxicity.includes("רעילות קלה")) return "#ffeb3b"; // Yellow
    return "transparent"; // Default color for unclassified toxicity
  };

  const renderTableRows = (rows, showActionButton = false) => {
    return rows.map((additive) => (
      <React.Fragment key={additive._id}>
        <TableRow>
          <TableCell>
            <IconButton size="small" onClick={() => toggleDetails(additive._id)}>
              {expandedId === additive._id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>{additive["מספר רשיון"]}</TableCell>
          <TableCell>{additive["שם תכשיר"]} ({additive["שם תכשיר אנגלי"]})</TableCell>
          <TableCell>{additive["חומר פעיל"]}</TableCell>
          <TableCell>{additive["ריכוז חומר פעיל"]}</TableCell>
          <TableCell
            style={{
              backgroundColor: getToxicityColor(additive["רעילות"]),
              color: "#fff",
            }}
          >
            {additive["רעילות"]}
          </TableCell>
          {showActionButton && (
            <TableCell>
              <Button
                variant="contained"
                color={isAdditiveInMyList(additive._id) ? "secondary" : "primary"}
                onClick={() => handleAddRemoveAdditive(additive)}
              >
                {isAdditiveInMyList(additive._id) ? t('removeAdditive') : t('addAdditive')}
              </Button>
            </TableCell>
          )}
        </TableRow>
        {expandedId === additive._id && (
          <TableRow>
            <TableCell colSpan={showActionButton ? 7 : 6} style={{ padding: 0 }}>
              <Box sx={{ p: 2, bgcolor: "#f9f9f9" }}>
                <Typography variant="body2">
                  <strong>{t("licenseNumber")}:</strong> {additive["מספר רשיון"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("type")}:</strong> {additive["סוג תכשיר"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("activityType")}:</strong> {additive["סוג פעילות"]} ({additive["סוג פעילות אנגלי"]})
                </Typography>
                <Typography variant="body2">
                  <strong>{t("casNumber")}:</strong> {additive["CAS.NO"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("resistanceGroup")}:</strong> {additive["קבוצת עמידות"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("targetCode")}:</strong> {additive["טרגט קוד"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("formulation")}:</strong> {additive["פורמולציה"]} ({additive["פורמולציה אנגלי"]})
                </Typography>
                <Typography variant="body2">
                  <strong>{t("cropGroup")}:</strong> {additive["קבוצת גידולים"]} ({additive["קבוצת גידולים אנגלי"]})
                </Typography>
                <Typography variant="body2">
                  <strong>{t("pestsGroup")}:</strong> {additive["קבוצת נגעים"]} ({additive["קבוצת נגעים אנגלי"]})
                </Typography>
                <Typography variant="body2">
                  <strong>{t("applicationDosage")}:</strong> {additive["מינון ליישום"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("applicationVolume")}:</strong> {additive["נפח ליישום"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("reentryInterval")}:</strong> {additive["כניסה מחדש"]}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("toxicityLevel")}:</strong> {additive["דרגת רעילות"]} ({additive["דרגת רעילות אנגלי"]})
                </Typography>
                <Typography variant="body2">
                  <strong>{t("manufacturer")}:</strong> {additive["בעל רשיון"]} ({additive["בעל רשיון אנגלי"]})
                </Typography>
                <Typography variant="body2">
                  <strong>{t("label")}:</strong>{" "}
                  <a href={additive["תווית"]} target="_blank" rel="noopener noreferrer">
                    {t("viewDetails")}
                  </a>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    ));
  };
  
  

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
          <Tab label={t('database')} />
          <Tab label={t('myAdditives')} />
        </Tabs>
      </Box>

      {tabIndex === 0 && (
        <Box>
            <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h5">{t('database')}</Typography>
            <TextField
                variant="outlined"
                placeholder={t('searchAdditives')}
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{ width: 300 }}
            />
            </Box>
            <Paper sx={{ p: 3 }}>
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                <CircularProgress />
                </Box>
            ) : additives.length === 0 ? (
                <Typography variant="body2" color="textSecondary" align="center">
                {t('noAdditivesFound')}
                </Typography>
            ) : (
                <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t("licenseNumber")}</TableCell>
                        <TableCell>{t("name")}</TableCell>
                        <TableCell>{t("activeIngredient")}</TableCell>
                        <TableCell>{t("activeIngredientConcentration")}</TableCell>
                        <TableCell>{t("toxicity")}</TableCell>
                        <TableCell>{t("actions")}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>{renderTableRows(additives, true)}</TableBody>
                </Table>
                </TableContainer>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
                <Button
                variant="contained"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(-1)}
                >
                {t("previous")}
                </Button>
                <Typography>
                {t("page")} {currentPage} {t("of")} {totalPages}
                </Typography>
                <Button
                variant="contained"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(1)}
                >
                {t("next")}
                </Button>
            </Box>
            </Paper>
        </Box>
        )}

      {tabIndex === 1 && (
        <Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {t('myAdditives')}
          </Typography>
          <Paper sx={{ p: 3 }}>
            {myAdditives.length === 0 ? (
              <Typography variant="body2" color="textSecondary" align="center">
                {t('noAdditivesYet')}
              </Typography>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>{t('licenseNumber')}</TableCell>
                      <TableCell>{t('name')}</TableCell>
                      <TableCell>{t('activeIngredient')}</TableCell>
                      <TableCell>{t('activeIngredientConcentration')}</TableCell>
                      <TableCell>{t('toxicity')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTableRows(myAdditives)}</TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Box>
      )}
    </Container>
  );
};

export default AdditivesPage;
