import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Card, CardContent, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ControllersList = ({ controllers = [], onControllerSelect }) => {
  const { t } = useTranslation();
  const [selectedState, setSelectedState] = useState(true); // Default: normal (true)
  const [filteredControllers, setFilteredControllers] = useState([]); // State for filtered controllers
  const [loading, setLoading] = useState(false); // Loading state

  // Update filteredControllers whenever controllers or selectedState changes
  useEffect(() => {
    const filtered = controllers.filter((controller) => controller.normal === selectedState);
    setFilteredControllers(filtered);
  }, [controllers, selectedState]);

  const handleControllerClick = async (id) => {
    setLoading(true); // Show loading animation
    await onControllerSelect(id); // Call the callback with the selected controller ID
    setLoading(false); // Hide loading animation
  };

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={3} sx={{ height: 'calc(70vh)', overflowY: 'auto', p: 2 }}>
        {/* State Toggle Buttons */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={selectedState ? "contained" : "outlined"}
              onClick={() => setSelectedState(true)}
            >
              {t('normal')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={!selectedState ? "contained" : "outlined"}
              onClick={() => setSelectedState(false)}
            >
              {t('abnormal')}
            </Button>
          </Grid>
        </Grid>

        {/* Loading Animation */}
        {loading && (
          <Grid container justifyContent="center" sx={{ mb: 2 }}>
            <CircularProgress />
          </Grid>
        )}

        {/* Controller List */}
        {!loading && (
          <Grid container spacing={2}>
            {filteredControllers.length > 0 ? (
              filteredControllers.map((controller) => (
                <Grid item xs={12} key={controller.id}>
                  <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                    <CardContent>
                      <Typography variant="subtitle1">{controller.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t('location')}: {controller.location?.name || t('unknown')}
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        {t('battery')}
                      </Typography>
                      <Typography variant="subtitle1">{controller.battCapacity || 0}%</Typography>
                    </CardContent>
                    <CardContent>
                      <Button variant="outlined" onClick={() => handleControllerClick(controller.id)}>
                        {t('view')}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
                {t('noControllers')}
              </Typography>
            )}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default ControllersList;
