// src/config/bugsnag.js
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import React from 'react';

Bugsnag.start({
  apiKey: '05bb16a0135754fe2feda3820e4c9597',
  plugins: [new BugsnagPluginReact()]
});

BugsnagPerformance.start({ apiKey: '05bb16a0135754fe2feda3820e4c9597' });

// Export the React ErrorBoundary for use in your application
export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
export default Bugsnag; // Export Bugsnag instance
