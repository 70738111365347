import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, Grid, Alert, Snackbar, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import loginBackground from '../assets/images/background2.jpg';
import logo from '../assets/images/logo_transparent.png';
import { AuthContext } from '../contexts/AuthContext';

const Login = ({ direction }) => {
  const { login } = useContext(AuthContext);
  const isDesktop = useMediaQuery('(min-width: 1300px)');
  const isMobile = useMediaQuery('(max-width: 600px)');

  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Track submission state
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await login(username, password); // Call the `login` function from AuthContext
      if(!response.error){
        setOpenSnackbar(false);
        navigate('/'); // Redirect to the dashboard
      }else{
        setError(response.message || 'invalid_credentials');
        setOpenSnackbar(true);
      }
    } catch (err) {
      setError(err.message || 'invalid_credentials');
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const BackgroundGridComponent = () => (
    <Grid
      item
      sm={4}
      md={7}
      sx={{
        backgroundImage: `url(${loginBackground})`,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: { xs: 'none', sm: 'block' },
      }}
    />
  );

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container component="main" sx={{ height: '100vh', width: isMobile ? undefined : '100vw', margin: 0, padding: 0 }}>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3,
          }}
        >
          <Box
            component="img"
            sx={{
              height: isMobile ? 80 : 130,
              mb: isMobile ? 5 : 10,
            }}
            alt="Logo"
            src={logo}
          />
          <Typography component="h1" variant={isMobile ? 'h6' : 'h5'}>
            {t('login')}
          </Typography>
          <Box component="form" noValidate dir={direction}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t('username')}
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              size={isMobile ? 'small' : 'medium'}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size={isMobile ? 'small' : 'medium'}
            />
            <Button
              sx={{
                mt: 3,
                mb: 2,
                py: isMobile ? 1 : 2,
              }}
              type="button"
              fullWidth
              variant="contained"
              onClick={handleLogin}
              disabled={isLoading}
            >
              {isLoading ? t('loggingIn') : t('login')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <BackgroundGridComponent />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Login;
