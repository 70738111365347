import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Typography, Paper, CircularProgress, Tabs, Tab } from '@mui/material';
import ControllersList from '../components/desktop/ControllersList';
import Charts from '../components/Charts'; // Adjust the path as per your file structure
import { useTranslation } from 'react-i18next';
import ApiService from '../ApiService';
import useMediaQuery from '@mui/material/useMediaQuery';

const Home = () => {
  const { t } = useTranslation();
  const [controllers, setControllers] = useState([]);
  const [selectedController, setSelectedController] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0); // For mobile tab switching

  const isMobile = useMediaQuery('(max-width: 600px)'); // Check if screen size is mobile

  useEffect(() => {
    // Fetch controllers from the API
    const fetchControllers = async () => {
      try {
        setLoading(true); // Show loading animation
        const response = await ApiService.getUserNodes(); // Fetch nodes from API
        if (!response.error) {
          setControllers(response.nodes);
        } else {
          console.error(response.message);
        }
        setLoading(false); // Hide loading animation
      } catch (error) {
        console.error('Error fetching controllers:', error);
        setLoading(false); // Hide loading animation
      }
    };

    fetchControllers();
  }, []);

  const handleControllerSelect = (controllerId) => {
    const selected = controllers.find((controller) => controller.id === controllerId);
    setSelectedController(selected);

    // Automatically switch to the "Charts" tab in mobile view
    if (isMobile) {
      setTabValue(1); // Switch to the "Charts" tab
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderDesktopView = () => (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Statistics */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>{t('controllers')}</Typography>
              <Typography variant="h4">10</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>{t('locations')}</Typography>
              <Typography variant="h4">5</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>{t('data')}</Typography>
              <Typography variant="h4">25</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>{t('alerts')}</Typography>
              <Typography variant="h4">3</Typography>
            </Paper>
          </Grid>

          {/* Controllers List */}
          <ControllersList controllers={controllers} onControllerSelect={handleControllerSelect} />

          {/* Graph Component */}
          <Grid item xs={12} md={8}>
            <Paper elevation={3}>
              {loading ? (
                <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                  <CircularProgress />
                </Grid>
              ) : selectedController ? (
                <Box
                  sx={{
                    height: 'auto',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Charts node={selectedController} />
                </Box>
              ) : (
                <Typography variant="h6" align="center">
                  {t('selectControllerToViewGraph')}
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );

  const renderMobileView = () => (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        {/* Tabs for Mobile View */}
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label={t('controllers')} />
          <Tab label={t('charts')} />
        </Tabs>

        {/* Tab Panels */}
        {tabValue === 0 && (
          <Box sx={{ mt: 2 }}>
            <ControllersList controllers={controllers} onControllerSelect={handleControllerSelect} />
          </Box>
        )}
        {tabValue === 1 && (
          <Paper elevation={3} sx={{ mt: 2 }}>
            {loading ? (
              <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                <CircularProgress />
              </Grid>
            ) : selectedController ? (
              <Box
                sx={{
                  height: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Charts node={selectedController} />
              </Box>
            ) : (
              <Typography variant="h6" align="center">
                {t('selectControllerToViewGraph')}
              </Typography>
            )}
          </Paper>
        )}
      </Box>
    </Container>
  );

  return isMobile ? renderMobileView() : renderDesktopView();
};

export default Home;
