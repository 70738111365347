// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client'; // React 18 entry point
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ErrorBoundary } from './configs/bugsnag'; // Import Bugsnag ErrorBoundary

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Render the application
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// Set the document direction to RTL
document.documentElement.setAttribute('dir', 'rtl');

// Measure performance metrics
reportWebVitals();
