import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import createCache from '@emotion/cache';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [direction, setDirection] = useState('ltr');
    const [cache, setCache] = useState(createCache({ key: 'ltr' })); // Unique key for initial direction

    const toggleLanguage = async (language) => {
        const newDirection = language === 'ar' || language === 'he' ? 'rtl' : 'ltr';
        setDirection(newDirection);
        document.body.setAttribute('dir', newDirection);

        // Ensure the cache key is unique for the new direction
        setCache(createCache({ key: newDirection }));
        await i18n.changeLanguage(language);
    };

    useEffect(() => {
        const currentLanguage = i18n.language;
        toggleLanguage(currentLanguage);
    }, [i18n]);

    return (
        <LanguageContext.Provider value={{ direction, cache, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
