import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  Modal,
  Collapse,
} from "@mui/material";
import { Add, CalendarToday, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TaskModal from "./modals/TaskModal";
import CategoryModal from "./modals/CategoryModal";
import { useTranslation } from 'react-i18next';

const TaskManagementPage = () => {
  const { t } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [expandedTaskId, setExpandedTaskId] = useState(null);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const categories = [
    {
      id: 1,
      name: "Plant Measurements",
      resultType: {
        name: "Height",
        dataType: "number",
        unit: "cm",
        description: "Measure the height of the plant.",
      },
    },
    {
      id: 2,
      name: "Irrigation",
      resultType: {
        name: "Irrigation Count",
        dataType: "number",
        unit: "times",
        description: "Number of times irrigation is performed.",
      },
    },
    {
      id: 3,
      name: "Maintenance",
      resultType: {
        name: "Completed",
        dataType: "boolean",
        description: "Whether the maintenance task is completed.",
      },
    },
  ];

  const mockTasks = [
    {
      id: 1,
      title: "Measure plant height",
      description: "Measure the height of plants in Field A to track growth.",
      timestamp: 1734211200000, // 2024-12-15
      category: { name: "Plant Measurements", color: "#4caf50" }, // Green
      status: "pending",
    },
    {
      id: 2,
      title: "Clean water tank",
      description: "Ensure the water tank is cleaned and refilled properly.",
      timestamp: 1734297600000, // 2024-12-16
      category: { name: "Maintenance", color: "#f44336" }, // Red
      status: "completed",
    },
  ];

  useEffect(() => {
    setTasks(mockTasks);
  }, []);

  const filteredTasks = tasks.filter(
    (task) => formatDate(task.timestamp) === formatDate(selectedDate)
  );

  const getDateColors = (tasks) => {
    const dateColors = {};

    tasks.forEach((task) => {
      const date = formatDate(task.timestamp);
      if (!dateColors[date]) {
        dateColors[date] = new Set();
      }
      dateColors[date].add(task.category.color);
    });

    return dateColors;
  };

  const openCalendarModal = () => setIsCalendarModalOpen(true);
  const closeCalendarModal = () => setIsCalendarModalOpen(false);

  const openTaskModal = () => setIsTaskModalOpen(true);
  const closeTaskModal = () => setIsTaskModalOpen(false);

  const handleOpenCategoryModal = () => setIsCategoryModalOpen(true);
  const handleCloseCategoryModal = () => setIsCategoryModalOpen(false);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    closeCalendarModal();
  };

  const toggleTaskDetails = (taskId) => {
    setExpandedTaskId(expandedTaskId === taskId ? null : taskId);
  };

  const handleCreateTask = (newTask) => {
    setTasks((prev) => [...prev, newTask]);
    closeTaskModal();
  };

  const handleCreateCategory = (newCategory) => {
    console.log("New Category:", newCategory);
    // Send `newCategory` to your backend API or update state
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
            mb: 2,
          }}
        >
          <Typography variant="h4">{t('tasks')}</Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={openTaskModal}
            >
              {t('createTask')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleOpenCategoryModal}
            >
              {t('createCategory')}
            </Button>
            <IconButton color="primary" onClick={openCalendarModal}>
              <CalendarToday />
            </IconButton>
          </Box>
        </Box>

        {/* Task Table */}
        <Paper sx={{ p: 3 }}>
          {filteredTasks.length === 0 ? (
            <Typography variant="body2" color="textSecondary">
              {t('noTasksForSelectedDate')}
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{t('title')}</TableCell>
                    <TableCell>{t('category')}</TableCell>
                    <TableCell>{t('status')}</TableCell>
                    <TableCell>{t('date')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTasks.map((task) => (
                    <React.Fragment key={task.id}>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => toggleTaskDetails(task.id)}
                          >
                            {expandedTaskId === task.id ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>{task.title}</TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              backgroundColor: task.category.color,
                              color: "white",
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              display: "inline-block",
                            }}
                          >
                            {task.category.name}
                          </Box>
                        </TableCell>
                        <TableCell>{task.status}</TableCell>
                        <TableCell>{formatDate(task.timestamp)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={5}
                        >
                          <Collapse
                            in={expandedTaskId === task.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ p: 2 }}>
                              <Typography variant="body2">
                                {task.description}
                              </Typography>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>

        {/* Create Task Modal */}
        <TaskModal
          open={isTaskModalOpen}
          onClose={closeTaskModal}
          onSubmit={handleCreateTask}
          categories={categories}
        />
        {/* Create Category Modal */}
        <CategoryModal
            open={isCategoryModalOpen}
            onClose={handleCloseCategoryModal}
            onSubmit={handleCreateCategory}
        />

        {/* Calendar Modal */}
        <Modal open={isCalendarModalOpen} onClose={closeCalendarModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              p: 4,
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              {t('selectDate')}
            </Typography>
            <DateCalendar
              value={selectedDate}
              onChange={handleDateChange}
              sx={{
                width: "100%",
                maxWidth: 500,
                margin: "0 auto",
              }}
              slotProps={{
                day: ({ day }) => {
                  const date = formatDate(day);
                  const colors = Array.from(getDateColors(tasks)[date] || []);
                  const background =
                    colors.length > 1
                      ? `linear-gradient(45deg, ${colors.join(",")})`
                      : colors[0];

                  return {
                    sx: {
                      ...(colors.length && {
                        background,
                        color: "white",
                        borderRadius: "50%",
                        border: "1px solid #ccc",
                      }),
                    },
                  };
                },
              }}
            />
          </Box>
        </Modal>
      </Container>
    </LocalizationProvider>
  );
};

export default TaskManagementPage;
