import React, { useState } from 'react';
import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const UsersPage = () => {
  const { t, i18n } = useTranslation(); // Assuming you are using translation keys
  const isRTL = i18n.dir() === 'rtl';

  // Demo user data
  const [users, setUsers] = useState([
    { id: 1, name: 'John Doe', email: 'john.doe@example.com', role: 'Admin' },
    { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', role: 'User' },
  ]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [newUser, setNewUser] = useState({ name: '', email: '', role: 'User' });

  // Handlers
  const handleDeleteUser = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    setDialogOpen(true);
  };

  const handleSaveUser = () => {
    if (currentUser) {
      setUsers(users.map((user) => (user.id === currentUser.id ? currentUser : user)));
    } else {
      setUsers([...users, { ...newUser, id: Date.now() }]);
    }
    setDialogOpen(false);
    setCurrentUser(null);
    setNewUser({ name: '', email: '', role: 'User' });
  };

  const handleChange = (field, value) => {
    if (currentUser) {
      setCurrentUser({ ...currentUser, [field]: value });
    } else {
      setNewUser({ ...newUser, [field]: value });
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentUser(null);
    setNewUser({ name: '', email: '', role: 'User' });
  };

  return (
    <Container maxWidth="lg" sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper style={{ padding: 20 }}>
          <Typography variant="h5" gutterBottom>
            {t('manageUsers')}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <List>
            {users.map((user) => (
              <ListItem key={user.id} sx={{ textAlign: isRTL ? 'right' : 'left' }}>
                <ListItemAvatar>
                  <Avatar>{user.name.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.name} secondary={`${user.email} - ${user.role}`} />
                <ListItemSecondaryAction sx={{ right: isRTL ? 'auto' : 0, left: isRTL ? 0 : 'auto' }}>
                  <IconButton edge="end" onClick={() => handleEditUser(user)}>
                    <Edit />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleDeleteUser(user.id)}>
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
              {t('addUser')}
            </Button>
          </Box>
        </Paper>
      </Box>

      {/* Dialog for Add/Edit User */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
        <DialogTitle>{currentUser ? t('editUser') : t('addUser')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('name')}
            sx={{
                '& .MuiInputBase-input': {
                  textAlign: isRTL ? 'right' : 'left', // Adjust text alignment
                },
                '& .MuiInputLabel-root': {
                  textAlign: isRTL ? 'right' : 'left', // Adjust label alignment
                },
              }}
            fullWidth
            value={currentUser ? currentUser.name : newUser.name}
            onChange={(e) => handleChange('name', e.target.value)}
            margin="normal"
            sx={{ textAlign: isRTL ? 'right' : 'left' }}
          />
          <TextField
            label={t('email')}
            fullWidth
            value={currentUser ? currentUser.email : newUser.email}
            onChange={(e) => handleChange('email', e.target.value)}
            margin="normal"
            sx={{ textAlign: isRTL ? 'right' : 'left' }}
          />
          <Select
            fullWidth
            value={currentUser ? currentUser.role : newUser.role}
            onChange={(e) => handleChange('role', e.target.value)}
            margin="normal"
            sx={{ textAlign: isRTL ? 'right' : 'left' }}
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="User">User</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={handleSaveUser} color="primary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UsersPage;
